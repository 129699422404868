import React from 'react';
import './Earth.scss'

function Earth() {
    return (
        <div className={'Earth'}>
                <div className="earth"> </div>
        </div>
    );
}

export default Earth;